import PropTypes from 'prop-types'
import React from 'react'
import desk from '../images/desk.jpg'
class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={desk} alt="background image" />
          </span>
          <p>
            I was born and raised in Ellenville, New York, and in 2015 moved to New York
            City to begin my legal career. After living and working in New York for 5
            years I decided it was time to come home and lay my roots down in a
            community that is growing and changing. Ellenville has become a vibrant and
            diverse village and I love being a part of the changing dynamic and being
            able to provide legal services to the community.
          </p>
          <h4>My office focuses on:</h4>
          <ul>
            <li>DWI's</li>
            <li>Traffic Tickets</li>
            <li>Real Estate Transactions</li>
            <li>Evictions</li>
            <li>Civil Litigation</li>
            <li>Copyrights</li>
            <li>Trademarks</li>
            <li>Intellectual Property Litigation</li>
            <li>E-Commerce Business Consulting</li>
            <li>Small Business Consulting</li>
          </ul>

          <p>
            My passion is helping entrepreneurs set their new business up in order to be
            successful. Setting up a business for the first time is an intimidating
            endeavor, and I aim to make the process easy and seamless so all you have to
            worry about is growing your business.
          </p>

          <p>
            I love watching this town and the surrounding area grow, and I want to be a
            resource for anyone that is moving here to start a new life, as well as
            those who currently live here and have established themselves as part of the
            community.

          </p>

          <p>
            I am licensed to practice law in the states of New York, New Jersey, Alaska,
            and the District of Columbia. I am also admitted in the Federal District
            Courts of Eastern District of New York, Southern District of New York, and
            the District of New Jersey.
          </p>

          <p>
            Whatever your goals may be, I look forward to meeting you and helping you
            achieve them

          </p>

          <p>-Alex</p>

          {close}
        </article>
        <article
          id="location"
          className={`${this.props.article === 'location' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Our Location</h2>
          <span className="image main">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2978.4514074262556!2d-74.40641224825492!3d41.71077718374422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89dcdb535d7e9a19%3A0x12c549e961606c8d!2s190%20S%20Main%20St%2C%20Ellenville%2C%20NY%2012428!5e0!3m2!1sen!2sus!4v1606242493269!5m2!1sen!2sus"
              width="100%"
              height="250"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </span>
          <p>
            You can find our Law Offices at 190 South Main Street Ellenville, NY
            12428
          </p>

          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <p>
            Reach out to us through any of the following:
            <br />
            Email: alex@alexlonsteinlaw.com <br />
            Phone: 845-905-2216 <br />
            Fax: 845-647-6277
          </p>
          <ul className="icons">
            <li>
              <a
                href="https://www.linkedin.com/in/alexander-lonstein-b84a6069/"
                className="icon fa-linkedin"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
